.get-updates__error-message {
    margin-top: 5px;
    color: white;
}

.get-updates__modal {
    padding: 30px;
}

.get-updates__modal-button__open {
    background: none;
    border: none;
    color: var(--blue-400);
    outline: none;
}

.get-updates__success {
    display: flex;
    align-items: center;
    color: white;
}

.get-updates__success-button {
    color: var(--gray-300);
    font-size: 40px;
    margin-right: 5px;
    margin-left: -8px;
}

.form-banner__description {
    font-size: 15px;
    color: white;
}
.form-banner__form {
    margin-top: 45px;
    max-width: 640px;
    padding: 40px;
}

.form-banner__title {
    font-size: 64px;
    font-family: Korolev;
    font-weight: bold;
    color: white;
}

.form-item {
    position: relative;
    display: block;
    margin-top: 20px;
    margin-bottom: 48px;
}

.form__label {
    position: absolute;
    left: 0;
    top: 3px;
    transition: .5s;

    font-size: 18px;
    font-weight: 500;
    color: var(--gray-500);
}

.form__label.has-value {
    top: -20px;
    font-size: 12px;
    color: var(--gray-100);
}

.form__label-slack {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 25px;

    font-size: 18px;
    font-weight: 500;
    color: var(--gray-500);
}

.form__input {
    padding: 5px;
    border-top: none;
    border-bottom: 1px solid var(--gray-500);
    border-left: none;
    border-right: none; 
    border-radius: 0px;

    height: 28px;
    width: 100%;
    transition: border .5s;

    font-size: 18px;
    font-weight: 500;
    background-color: transparent;
    outline: none;
    color: var(--gray-100);
}

.form__input:focus,
.form__input:hover {
    border-bottom: 1px solid var(--blue-400);
}

/* Remove autofill browser styles */
.form__input:-webkit-autofill,
.form__input:-webkit-autofill:hover, 
.form__input:-webkit-autofill:focus, 
.form__input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--gray-800) inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: var(--gray-100) !important;
}

.form__submit {
    z-index: 2;
    transition: background-color 375ms;
    border: 0;
    border-radius: 100px;

    font-size: 18px;
    font-weight: 500;
    min-width: 150px;
    padding: 16px;

    text-align: center;
    background-color: var(--blue-400);
    color: white;
    outline: none;
}

.form__submit:hover {
    background: rgba(10,15,20,.9);
}

.get-updates {
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 56px;
    padding-right: 56px;
    background: var(--gray-800);
}

.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}
  
  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: white;
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}}
  

@media screen and (min-width: 746px) {
    .form__item {
        margin-bottom: 16px;
        margin-left: 32px;
        width: calc(50% - 32px);
        min-width: calc(50% - 32px);
    }
}

@media screen and (min-width: 910px) {
    .form-banner__description {
        font-size: 18px;
        line-height: 1.56;
    }

    .form-banner__form {
        margin-top: 16px;
        width: 50%;
    }

    .form-banner__text {
        margin-right: 84px;
        width: calc(50% - 84px);
    }

    .form-banner__title {
        font-size: 88px;
    }

    .get-updates {
        padding-top: 120px;
        padding-bottom: 120px;
        padding-left: 56px;
        padding-right: 56px;
    }
}

@media screen and (max-width: 909px) {
    .get-updates {
        flex-direction: column;
    }
}