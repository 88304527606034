.plant-map {
  width: 100%;
  height: 100%;
}

/* TODO: share wtih branch-map__legend */
.plant-map__legend {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background: rgb(255, 255, 255, .88);
}