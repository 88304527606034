.accordion-section-child {
  display: flex;
}

.accordion-section-child__text,
.accordion-section-child__graph {
  justify-content: flex-start;
}

.accordion-section-child__text {
  padding: 50px 24px;
}

.accordion-section-child__text-main p {
  padding-right: 80px;
  color: var(--gray-600);
}

.accordion-section-child__text-main p:first-child {
  margin-top: 0px;
}

.accordion-section-child__text-main a {
  color: var(--blue-400);
}

/* Graph section */
.accordion-section-child__graph-child {
  position: sticky;
  top: 93px;
}

.accordion-section-child__graph-caption {
  padding: 50px 0px 50px 35px;
  color: var(--gray-700);
  background-color: var(--gray-100);
}

.accordion-section-child__graph-bg {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  border: var(--border);
  background-color: var(--gray-100);
}


/* Graph controls section */
.accordion-section-child__graph-controls {
  flex-direction: column;
  padding: 0px 40px;
  border: var(--bokeh-border);
  border-bottom: none;
  color: var(--gray-700);
}

.accordion-section-child__graph-controls p {
  height: 84px;
}

.accordion-section-child__graph-controls p:last-child{
  margin-bottom: 0px;
}


/* Toggle section */
.accordion-section-child__graph-toggle {
  margin-top: 30px;
  color: var(--gray-300);
  font-size: var(--responsive-font-XL);
  font-weight: 600;
}

.accordion-section-child__graph-toggle-button {
  padding: 5px 20px;
  transition: var(--transition);
}

.accordion-section-child__graph-toggle-button:first-child {
  padding-left: 0px;
  text-align: right; /* handles text wrapping */
}

.accordion-section-child__graph-toggle-button:last-child {
  padding-right: 0px;
}

.accordion-section-child__graph-toggle .button-group__active {
  color: var(--blue-400);
  background-color: unset;
}

.accordion-section-child__graph-toggle-button:not(.button-group__active):hover {
  color: var(--gray-600);
}


/* Images */
.accordion-section-child__image-wrapper {
  position: relative;
  margin-top: 30px;
  padding: 0px 40px 20px 40px;
}

.accordion-section-child__image-wrapper img {
  width: 100%;
}



@media screen and (max-width: 909px) {
  .accordion-section-child {
    flex-direction: column;
  }

  .accordion-section-child__text {
    padding: 50px 0px 0px 0px;
  }

  .accordion-section-child__text-main p {
    padding: 0px 24px;
  }

  .accordion-section-child__graph-caption {
    padding: 35px 24px 5px 24px;
    margin: 0px;
    background-color: unset;
  }

  .accordion-section-child__graph-controls {
    padding: 0px 24px;
  }

  .accordion-section-child__image-wrapper {
    margin-top: 30px;
    padding: 0px 24px 20px 24px;
  }
}

@media screen and (min-width: 910px) {
  /* These make the clean energy bar and storage images look much sharper. We
     only do crisp rendering on larger screens because things get pixel-y at
     smaller resolutions */
  .accordion-section-child__graph__capacity img,
  .accordion-section-child__graph__storage img {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
  }
}
