.hero {
  position: relative;
}

.hero__visible .hero__header {
  box-shadow: none;
}

.hero__header {
  position: fixed;
}

.hero__bg {
  position: absolute;
  height: 100%;
  width: 100%;

  background-size: cover;
  background-position: center;

  z-index: -1;
}

.hero__video {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero__blue .hero__video {
  /* colors pulled from video */
  background: linear-gradient(to bottom, #116bd2, #0644ab);
}

.hero__wrapper {
  display: flex;
  align-items: flex-end;

  min-height: 50vh;
  padding-top: 264px;
}

.hero__has-video {
  background-color: unset;
}

.hero__fullscreen {
  min-height: 100vh;
  padding-bottom: 12vh;
}

h2.hero__title {
  flex-shrink: 0;
  /* max-width chosen to keep home page title to two lines */
  width: 100%;
  max-width: 1200px;
  margin: 0px;
  padding: 45px 45px 68px 45px;
  color: var(--gray-900);
  font-size: 96px;
  z-index: 1;
}

.hero__fullscreen .hero__title {
  font-size: 120px;
}

.hero__blue_bg .hero__title {
  color: white;
}

@media screen and (max-width: 909px) {
  h2.hero__title {
    width: 100%;
    max-width: 500px;
    padding: 30px 5px 30px 30px;
    font-size: 70px;
  }

  .hero__fullscreen .hero__title {
    font-size: 76px;
  }
}
