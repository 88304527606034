.macro-grid-insight {
  padding: 120px 24px;
}

.macro-grid-insight__download-pdf-button {
  margin-top: 20px;
  font-family: 'Rational';
  font-size: var(--responsive-font-M);
}

.macro-grid-insight h3 {
  font-size: var(--responsive-font-L);
  font-weight: 400;
}

.macro-grid-insight__transmission-maps {
  padding: 0px 24px;
}

.macro-grid-insight__transmission-maps p {
  font-size: var(--responsive-font-M-Plus);
  text-align: center;
}

.macro-grid-insight img {
  width: 100%;
}

.macro-grid-insight__bottom-image {
  height: var(--section-height);
  padding: 24px;
  background: url('https://bescdn.breakthroughenergy.org/publications/wind_turbine_hills_and_water.jpg');
  color: white;
}

@media screen and (max-width: 899px) {
  .macro-grid-insight__transmission-maps {
    padding: 0px;
  }

  .macro-grid-insight__transmission-maps p {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 900px) {
  .macro-grid-insight__transmission-maps {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content 1fr;
  }

  .macro-grid-insight__transmission-maps p {
    padding: 0px 10px;
  }
}
