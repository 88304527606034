.homepage__title-small-text {
  font-family: 'rational';
  font-size: var(--responsive-font-XL);
  font-weight: 500;
}

.homepage__title-main-text {
  margin-bottom: 10px;
}

/* Styles all text in SVGs -- used for Nivo graphs */
.homepage text {
  font-family: 'Rational', sans-serif !important;
}
