.hook-section {
  padding: 100px 0px 150px 0px;
  color: white;
  background: linear-gradient(var(--blue-600), var(--blue-400), var(--cyan));
}

.hook-section__content-wrapper {
  padding: 100px 24px;
}

/* overrides styles from parent */
.hook-section__content-wrapper p {
  color: unset;
  font-size: var(--responsive-font-M-Plus);
}

.hook-section h3 {
  font-size: var(--responsive-font-L);
  margin-bottom: 50px;
  font-weight: 400;
}

@media screen and (max-width: 909px) {
  .hook-section {
    padding: 0px 0px 25px 0px;
  }

  .hook-section__intro p {
    margin-bottom: 0px;
  }
}
