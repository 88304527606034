.open-source-software__title-main-text {
  margin-bottom: 24px;
}

.open-source-software__title-small-text {
  margin-bottom: 24px;
  font-family: 'rational';
  font-size: var(--responsive-font-XL);
  font-weight: 500;
}

.open-source-software__github-link {
  font-family: 'rational';
  font-size: var(--responsive-font-M);
}

.open-source-software__text {
  padding: 120px 24px;
}

.open-source-software__text h3 {
  font-size: var(--responsive-font-L);
  font-weight: 400;
}

.open-source-software__image {
  display: flex;
  align-items: center;
  height: var(--section-height);
  padding: 24px;
  background: url('https://bescdn.breakthroughenergy.org/open-source-release/open_source_release_footer_blue.jpg');
  color: white;
}

@media screen and (max-width: 909px) {
  /* TODO: maybe make this default behavior in hero when we have a bg video or image */
  .open-source-software__hero {
    height: 100vh;
    padding-bottom: 12vh;
  }

  .open-source-software__title-main-text {
    padding-top: 100px;
  }

  .open-source-software__text {
    padding: 60px 24px;
  }

  .open-source-software__image {
    height: 100vh;
  }
}
