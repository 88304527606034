.grid-section {
  padding: 100px 24px;
}

.grid-section__graph {
  max-width: 948px;
  margin: 40px 0px;
}

.grid-section__graph .small-caps {
  padding-left: 5px;
}

.grid-section__model-link {
  width: fit-content;
  margin-top: 60px;
  color: var(--gray-800);
  font-size: 21px;
  font-weight: 500;
}

.grid-section__model-link:hover {
  color: var(--blue-400);
}

.grid-section__model-link__arrow {
  position: relative;
  height: 35px;
  width: 35px;
  margin: -1px 12px 0px 0px;
  padding: 9px;
  color: white;
  background-color: var(--blue-400);
  border-radius: 50%;
}

.grid-section__graph-wrapper {
  margin: 15px 0px 40px 0px;
}

.grid-section__graph-wrapper img {
  width: 100%;
}
