.footer {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 48px;

  width: 100%;
  padding-top: 120px;
  padding-left: 100px;
  padding-right: 100px;

  background-color: var(--black);

  font-weight: 500px;
  line-height: 16px;
  font-size: 14px;
}

.footer__container {
  justify-content: center;
}

/* TODO: mobile margins */

.footer__email {
  align-items: center;
  margin-top: 24px;
}

.footer__email a {
  margin-left: 12px;
  font-weight: 500;
}

.footer__email a:hover {
  color: var(--blue-400);
}

.footer__email-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: var(--blue-400);
}

.footer__logo-svg {
  width: 80px;
  height: 80px;
}

.footer__logo-wrapper {
  align-items: center;
  justify-content: center;
  grid-row: 1/2;

  display: flex;
}

.footer__nav-col-title {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--gray-500);
}

.footer__nav-col {
  flex-basis: 50%;
  grid-row: 1/2;
 
  border-left: 1px solid #414448;

  padding-left: 24px;
  color: #fff;
}

.footer__links {
  align-items: center;
  justify-content: space-between;
  grid-column: 1/2;

  display: flex;

  font-size: 12px;
  line-height: 16px;
  transition: color .25s;
  color: var(--gray-500);
}

.footer__copywright {
  align-items: center;
  justify-content: flex-end;
  grid-column: 2/2;

  display: flex;

  padding-left: 24px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255,255,255,.3);
}