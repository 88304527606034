.conclusion-section {
  display: flex;
  align-items: flex-start;
  padding: 100px 24px;
  color: white;
  background: linear-gradient(159deg, var(--accordion-end), var(--blue-500) 50%, var(--cyan));
}

.conclusion-section p {
  color: unset;
}

.conclusion-section h2 {
  max-width: 510px;
  margin: 0px 50px 0px 0px;
}

.conclusion-section__dashboard-link {
  margin-top: 50px;
}

@media screen and (max-width: 909px) {
  .conclusion-section {
    flex-direction: column;
    padding-bottom: 160px;
  }

  .conclusion-section h2 {
    /* The text wraps in an ugly way at 360px and we're
       literally two pixels short so I'm bumping it up */
    min-width: 314px;
    max-width: unset;
    margin: 0px 0px 30px 0px;
  }
}
