.accordion-section__intro {
  padding: 0px 24px 100px 24px;
}

.accordion-section__accordion__toggle {
  height: 150px;
  padding: 0px 24px;
  color: white;
  cursor: pointer;
}

.accordion-section__toggle-title {
  margin: 5px 0px 0px 0px;
  font-size: 44px;
}

.accordion-section__accordion:nth-child(1) .accordion-section__accordion__toggle {
  background: linear-gradient(to right, var(--accordion-1), var(--accordion-2));
}

.accordion-section__accordion:nth-child(2) .accordion-section__accordion__toggle {
  background: linear-gradient(to right, var(--accordion-2), var(--accordion-3));
}

.accordion-section__accordion:nth-child(3) .accordion-section__accordion__toggle {
  background: linear-gradient(to right, var(--accordion-3), var(--accordion-4));
}

.accordion-section__accordion:nth-child(4) .accordion-section__accordion__toggle {
  background: linear-gradient(to right, var(--accordion-4), var(--accordion-5));
}

.accordion-section__accordion:nth-child(5) .accordion-section__accordion__toggle {
  background: linear-gradient(to right, var(--accordion-5), var(--accordion-6));
}

.accordion-section__accordion:nth-child(6) .accordion-section__accordion__toggle {
  background: linear-gradient(to right, var(--accordion-6), var(--accordion-end));
}

@media screen and (max-width: 909px) {
  .accordion-section__toggle-title {
    font-size: 38px;
  }
}
