/* TODO: share css between model and publications pages? */

.about-the-model {
  padding: 0px 24px 120px 24px;
  color: var(--gray-900);
}

.about-the-model h2 {
  margin-top: 120px;
}

.about-the-model a {
  word-break: break-all;
}

.about-the-model h3 {
  margin-top: 50px;
  font-size: var(--responsive-font-L);
}

.about-the-model__img-wrapper {
  padding: 0px 24px;
}

.about-the-model__img-wrapper img {
  width: 100%;
}

h3.references-h3 {
  margin-top: unset;
}

@media screen and (max-width: 899px) {
  .about-the-model h2 {
    margin-top: 100px;
    margin-bottom: 30px;
  }

  .about-the-model h2:first-child {
    margin-top: 60px;
  }

  .about-the-model__img-wrapper {
    padding: 0px;
  }
}
