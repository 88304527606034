.dashboard {
  background-color: var(--gray-100);
  overflow-x: auto;
  height: 100%;
}

.dashboard__header {
  background-color: var(--gray-900);
}

.dashboard__graph__grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: max-content 1fr;
  min-height: calc(100% - var(--header-height)*2 - 2px - 10px); /* Take header + border into account */
}

.dashboard__footer {
  max-width: 800px;
  margin: auto;
  padding-bottom: 15px;
}

.dashboard__footer a {
  color: var(--blue-400);
}
