/* TODO: share css between model and publications pages? */

.publications__section {
  padding: 120px 0px;
}

.publications__section:not(:last-child) {
  border-bottom: var(--border);
}

.publications__details {
  padding: 0px 24px 10px 24px;
}

.publications__title {
  margin: 0px;
  font-size: var(--responsive-font-header-S);
}

.publications__details p,
.publications__soon {
  color: var(--gray-700);
  font-size: var(--responsive-font-M-Plus);
  font-style: italic;
}

.publications__text {
  padding-left: 24px;
}

.publications__text p {
  padding-right: 50px;
}

.publications__text p:first-child {
  margin-top: 0;
}

.publications__text,
.publications__image {
  align-self: flex-start;
}

.publications__link {
  margin-top: 40px;
}

.publications__image {
  position: sticky;
  top: var(--header-height);
}

.publications__image img {
  width: 100%;
}

.publications__soon {
  margin-top: 45px;
}

/* Media queries do not support vars */
@media screen and (max-width: 909px) {
  .publications__section {
    padding: 60px 0px;
  }

  .publications__content {
    flex-direction: column-reverse;
  }

  .publications__section:not(:last-child) {
    padding-bottom: 50px;
  }

  .publications__section:not(:first-child) {
    padding-top: 50px;
  }

  .publications__text p {
    padding-right: 24px;
  }

  .publications__image {
    position: unset;
    margin-bottom: 30px;
  }
}
